/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ImgExplanation from '../components/ImgExplanation';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "The reason businesses love reviews from recent customers is because they attract new customers."), "\n", React.createElement(_components.p, null, "After all the work you do to collect a review you definitely want to make sure you are getting every last bit of value out of it."), "\n", React.createElement(_components.p, null, "Here are five ways you can leverage your reviews."), "\n", React.createElement(_components.h2, {
    id: "advertising",
    style: {
      position: "relative"
    }
  }, "Advertising", React.createElement(_components.a, {
    href: "#advertising",
    "aria-label": "advertising permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "People like cold hard facts."), "\n", React.createElement(_components.p, null, "Rather than saying \"we do great work\" try relaying some stats for your business that show you do great work. An example being \"An average of 4.8 out of five stars on Google\"."), "\n", React.createElement(_components.p, null, "Here is a trick, if you are just getting started collecting reviews and don't have huge review counts yet, but do have a good overall rating, stick with a phrase like \"An average of 4.8 out of five stars on Google\"."), "\n", React.createElement(_components.p, null, "Once your review request program has been running for a bit and you have amassed a large number of glowing reviews you can incorporate phrases like \"Over 100 five star reviews on Google\"."), "\n", React.createElement(_components.p, null, "Review related statistics can be included in all forms of advertising. Print, digital even radio ads or TV commercials."), "\n", React.createElement(ImgExplanation, {
    explanation: "Advertise with reviews."
  }, React.createElement("img", {
    src: "/advertising_with_reviews.png",
    alt: "Advertise with reviews."
  })), "\n", React.createElement(_components.h2, {
    id: "social-content",
    style: {
      position: "relative"
    }
  }, "Social Content", React.createElement(_components.a, {
    href: "#social-content",
    "aria-label": "social content permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Reviews are a great source of content for social media."), "\n", React.createElement(_components.p, null, "It can be tough for businesses to come up with the endless stream of topics required to keep a social account going."), "\n", React.createElement(_components.p, null, "A common technique is to have a reoccurring structure for a post."), "\n", React.createElement(_components.p, null, "Reviews lend themselves to this perfectly."), "\n", React.createElement(_components.p, null, "For example your business account could pick a glowing review to feature in a \"Review of the Week\" post."), "\n", React.createElement(ImgExplanation, {
    explanation: "Social post of Review."
  }, React.createElement("img", {
    src: "/review_social_post.png",
    alt: "Social post of Review."
  })), "\n", React.createElement(_components.h2, {
    id: "closing-deals",
    style: {
      position: "relative"
    }
  }, "Closing Deals", React.createElement(_components.a, {
    href: "#closing-deals",
    "aria-label": "closing deals permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In addition to being great for attracting leads, reviews can also help you close deals with those leads."), "\n", React.createElement(_components.p, null, "Reviews help build up trust in your business. That is why they are great to reference in a proposal or quote."), "\n", React.createElement(_components.p, null, "By including a review rating or a few testimonials from past customers you are showing the potential customer your business has a track record of delivering the work outlined in the quote."), "\n", React.createElement(ImgExplanation, {
    explanation: "Review caption."
  }, React.createElement("img", {
    src: "/review_caption.png",
    alt: "Review caption."
  })), "\n", React.createElement(_components.h2, {
    id: "email-signature",
    style: {
      position: "relative"
    }
  }, "Email Signature", React.createElement(_components.a, {
    href: "#email-signature",
    "aria-label": "email signature permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "When you are describing your business in your email signature why not utilize a review rating to get the message across."), "\n", React.createElement(_components.p, null, "Anyone can say \"We provide excellent customer service\", but a high review rating shows you have a track record of doing so."), "\n", React.createElement(_components.p, null, "People will always trust a review rating more than a statement you make because a review rating is a third parties opinion."), "\n", React.createElement(ImgExplanation, {
    explanation: "Rating in email signature."
  }, React.createElement("img", {
    src: "/review_rating_in_email_signature.png",
    alt: "Rating in email signature."
  })), "\n", React.createElement(_components.h2, {
    id: "website",
    style: {
      position: "relative"
    }
  }, "Website", React.createElement(_components.a, {
    href: "#website",
    "aria-label": "website permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The tried and tested classic that is still just as important today as ever."), "\n", React.createElement(_components.p, null, "Yes, it is good to have reviews included in your website for customers that are not already coming to your site through a review platform."), "\n", React.createElement(_components.p, null, "One tip is to make sure your site is pulling in reviews in real time. Don't hardcode reviews directly in the site."), "\n", React.createElement(_components.p, null, "It will save you the ongoing work of updating your site and there is no sense in telling customers you have 95 reviews when you actually have 105."), "\n", React.createElement(ImgExplanation, {
    explanation: "Review section in website."
  }, React.createElement("img", {
    src: "/review_section_on_website.jpg",
    alt: "Review section in website."
  })));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
